<template>
  <div id="projectInfo">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">工程概况</span>
          <dv-decoration-3 style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            " />
        </div>
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box">
          <a-list item-layout="horizontal">
            <a-list-item>
              <a-list-item-meta :description="data.Name || ''">
                <span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">工程名称</span></a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta :description="data.DevelopmentOrg.Name || '无数据'">
                <span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">建设单位</span>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta :description="data.SupervisionOrg.Name || '无数据'">
                <span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">监管单位</span>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta :description="data.ConstructionOrg.Name || '无数据'">
                <span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">施工单位</span>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta :description="data.Acreage">
                <span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">建筑体量</span></a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta :description="data.Money || '0'"><span slot="title"
                      style="padding-right: 6.82407px; padding-left: 25.9315px">工程造价(万元)</span>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <!-- <div class="d-flex">
            <span class="coin">￥</span>
            <dv-digital-flop
              :config="item.number"
              style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <p class="text" style="text-align: center">
            {{ item.text }}
            <span class="colorYellow">(件)</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProInfoCache from "@/utils/cache/ProInfoCache"
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data () {
    return {
    };
  },
  watch: {
    pro: {
      deep: true,
      handler () {
        this.oppro();
      }
    }
  },
  components: {},
  mounted () {
  },
  methods: {
    oppro () {
      this.pro = ProInfoCache.getProInfo()
      this.$http
        .post("/Projects/ProEvent/GetDataList", {
          Search: {
            ProId: this.pro.Id,
          }
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.progressInfo = resJson.Data;
          }
        });
    },
  },
};
</script>

<style lang="scss">
#projectInfo {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      /*  float: right; */
      position: relative;
      /* width: 50%; */
      color: #d3d6dd !important;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      .ant-list {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-list-item {
              border-bottom: 0px;
              .ant-list-item-meta {
                .ant-list-item-meta-content {
                  .ant-list-item-meta-title {
                    color: #c3cbde !important;
                  }
                  .ant-list-item-meta-description {
                    color: aliceblue !important;
                    font-size: 12px;
                    padding-left: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>